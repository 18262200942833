import React from 'react'
import Papa from 'papaparse'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import './App.css'
import BootstrapTable from 'react-bootstrap-table-next'

const columns = [{
  dataField: 'name',
  text: 'Whiskey',
  sort: true
}, {
  dataField: 'type',
  text: 'Type',
  sort: true
}, {
  dataField: 'price',
  text: 'Price',
  sort: true,
  sortFunc: (a, b, order, dataField, rowA, rowB) => {
    const intA = parseFloat(a.replace('$', ''), 10)
    const intB = parseFloat(b.replace('$', ''), 10)
    if (intA === intB) {
      if (rowA.type === rowB.type) {
        return rowA.name > rowB.name // desc
      } else {
        return rowA.type > rowB.type
      }
    } else {
      if (order === 'asc') {
        return intB - intA
      }
      return intA - intB // desc
    }
  }
}]

class App extends React.Component {
  constructor (props) {
    super(props)
    this.handleSearch = this.handleSearch.bind(this)
    this.state = {
      whiskies: [],
      filteredWhiskies: [],
      search: '',
      loading: true
    }
  }

  componentDidMount () {
    Papa.parse('./whiskey.csv', {
      download: true,
      complete: (results) => {
        if (results.errors.length === 0) {
          const data = results.data.filter(result => {
            return (result[0] !== '' && result[0] !== 'Whiskey Menu' && result[0] !== 'Name' && result[0] !== 'Tellus3Whiskey')
          }).map(whiskey => {
            return {
              name: whiskey[0].trim(),
              type: whiskey[1].trim(),
              price: whiskey[2].trim()
            }
          })
          this.setState({ whiskies: data, filteredWhiskies: data, loading: false })
        } else {
          results.errors.map(error => console.error(error))
        }
      }
    })
  }

  handleSearch (event) {
    const search = event.target.value
    const whiskies = this.state.whiskies.filter(whiskey => {
      return (whiskey.name.toLowerCase().includes(search) || whiskey.type.toLowerCase().includes(search))
    })
    this.setState({
      search,
      filteredWhiskies: whiskies
    })
  }

  render () {
    return (
      <div>
        <div id='header'>
          <img src='./bottles.jpg' alt='whiskey bottles on the bar' className='img-fluid' />
          <h1>Tellus3Whiskey</h1>
        </div>
        <div className='container-xl'>
          <form>
            <div className='form-group'>
              <label htmlFor='search' className='sr-only'>Search</label>
              <input type='text' className='form-control' id='search' placeholder='SEARCH' value={this.state.search} onChange={this.handleSearch} />
            </div>
          </form>
          <BootstrapTable keyField='name' condensed columns={columns} data={this.state.filteredWhiskies} loading={this.state.loading} bootstrap4 />
        </div>
        <div className='footer'>
          Built by <a href='https://www.brewcore.com/about/' target='_blank' rel='noopener noreferrer'>Brewcore</a>
        </div>
      </div>
    )
  }
}

export default App
